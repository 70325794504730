import "bootstrap/dist/css/bootstrap.min.css";
import { useState, memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMetadata } from "../../models/metadata/metadata.selectors";

export const Header = memo(() => {
  const [active, setActive] = useState(false);

  const metadata = useSelector(getMetadata);

  const activeHeader = () => {
    if (window.innerWidth < 1200) {
      setActive(!active);
      document.body.classList.toggle("no-scroll");
    }
  };

  if (!metadata) {
    return <div></div>;
  }

  return (
    <header className={active ? "active" : ""}>
      <div className="container">
        <div className="row">
          <div className="col-2 col-lg-2">
            <Link className="logo" to="/">
              <img src="/images/logo.jpg" alt="" />
            </Link>
          </div>
          <div className="col-lg-4 col-xl-6 gx-2 d-none d-xl-block nav-wrap">
            <ul className="nav">
              {metadata?.menu?.header_main_menu.map((e: any, i: number) => {
                return (
                  <li key={i}>
                    <Link onClick={activeHeader} to={`${e.url}`}>
                      {e.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-12 col-md-8 col-xl-4 mt-md-1 pt-md-2 gx-2 text-md-end d-none d-md-flex d-xl-block flex-row-reverse avatar-wrap">
            <div className="avatar" style={{ backgroundImage: "url(../images/avatar.jpg)" }}>
              <div className="avatar-info">
                <div className="phone">
                  <a href={`tel:${metadata?.metadata_phone[0]}`}>{metadata?.metadata_phone[0]}</a>
                </div>
                <div className="avatar-name text-start text-md-end text-xl-start">
                  / Максим (онлайн)
                </div>
                <div className="avatar-email text-start text-md-end text-xl-start">
                  <a href={`mailto:${metadata?.metadata_email[0]}`}>
                    {metadata?.metadata_email[0]}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 d-xl-none text-end">
            <button className="burger" onClick={activeHeader}>
              <span />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
});
