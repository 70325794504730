import { FC } from "react";
import { useModal } from "../../../models/modalContext/ModalContext";

type TargetDivPropsType = {
  setIsShown: (isShown: number) => void;
};

export const TargetDiv: FC<TargetDivPropsType> = ({ setIsShown }) => {
  const { isOpenModal, setIsOpenModal, setOrderModal, setFeedback } = useModal();

  const clickHelper = () => {
    setIsOpenModal(true);
    setOrderModal(true);
    setFeedback(false);
  };

  return isOpenModal ? null : (
    <div className="interactive-picture__custom-buttons">
      <div
        className="ip__cb-item ip__cb-item-1 ipcbi-1"
        id={"1"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-2 ipcbi-1"
        id={"1"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-3 ipcbi-1"
        id={"1"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-4 ipcbi-2"
        id={"2"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-5 ipcbi-3"
        id={"3"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-6 ipcbi-4"
        id={"4"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-7 ipcbi-5"
        id={"5"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-8 ipcbi-5"
        id={"5"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-9 ipcbi-5"
        id={"5"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-10 ipcbi-5"
        id={"5"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-11 ipcbi-6"
        id={"6"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-12 ipcbi-6"
        id={"6"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-13 ipcbi-7"
        id={"7"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-14 ipcbi-7"
        id={"7"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-15 ipcbi-8"
        id={"8"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-16 ipcbi-8"
        id={"8"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-17 ipcbi-8"
        id={"8"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-18 ipcbi-8"
        id={"8"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-19 ipcbi-9"
        id={"9"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>

      <div
        className="ip__cb-item ip__cb-item-20 ipcbi-10"
        id={"10"}
        onMouseEnter={(e) => setIsShown(Number((e.target as HTMLDivElement).id))}
        onMouseLeave={() => setIsShown(0)}
        onClick={clickHelper}
      ></div>
    </div>
  );
};
