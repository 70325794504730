import "./button.scss"

export const DefaultButton = ({
  classNamesButton,
  onClickButton,
  buttonText,
}) => {
  return (
    <button className={classNamesButton} onClick={onClickButton}>
      {buttonText}
    </button>
  );
};
