import { put, takeEvery, call } from "redux-saga/effects";
import { FETCH_DATA, setMetadata } from "./metadata.actions";
import { AxiosResponse } from "axios";
import { getMetadataRequest } from "./metadata.services";

function* fetchSagaRequest(request: any, actionCreator: any, payload = {}) {
  try {
    const response: AxiosResponse = yield call(request, payload);
    const action = actionCreator(response.data);
    yield put(action);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function* getMetadata() {
  yield call(fetchSagaRequest, getMetadataRequest, setMetadata);
}

export default function* metadataSaga() {
  yield takeEvery(FETCH_DATA, getMetadata);
}
