import { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { DefaultButton } from "../../button/defaultButton/DefaultButton";
import "./videoModal.scss";
import { useModal } from "../../../../models/modalContext/ModalContext";

type VideoModalTypes = {
  contentLabel: string;
  overlayClassName: string;
  className: string;
  videoSrc: string;
};

type VideoResolution = {
  width: string | number;
  height: string | number;
};

export const VideoModal: FC<VideoModalTypes> = ({
  contentLabel,
  className,
  overlayClassName,
  videoSrc,
}) => {
  const { videoIsOpen, setVideoIsOpen } = useModal();
  const [width, setWidth] = useState(window.innerWidth);
  const [videoResolution, setVideoResolution] = useState<VideoResolution>({ width: 0, height: 0 });
  const isMobile = width <= 768;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setVideoResolution({ width: "100%", height: 240 });
    } else {
      setVideoResolution({ width: 1280, height: 720 });
    }
  }, [isMobile]);

  return (
    <Modal
      isOpen={videoIsOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setVideoIsOpen(false)}
      contentLabel={contentLabel}
      className={`Modal ${className}`}
      overlayClassName={`Overlay ${overlayClassName}`}
      ariaHideApp={false}
    >
      <video
        width={isMobile ? "100%" : videoResolution.width}
        height={isMobile ? "100%" : videoResolution.height}
        autoPlay
        controls
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <DefaultButton
        classNamesButton="closeModalButton"
        buttonText="✖"
        onClickButton={() => setVideoIsOpen(false)}
      />
    </Modal>
  );
};
