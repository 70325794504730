import { SET_PAGES, FETCH_PAGES } from "./pages.actions";

const pageInitialState = {
  pages: null,
  fetching: false,
};

export const pagesReducer = (state = pageInitialState, action: any) => {
  switch (action.type) {
    case FETCH_PAGES:
      return {
        ...state,
        fetching: true,
      };

    case SET_PAGES:
      return {
        ...state,
        pages: action.payload,
        fetching: false,
      };

    default:
      return state;
  }
};
