import { FC } from "react";
import { AppRouter } from "../../routes/AppRouter";
// import { Provider} from "react-redux";
// import store from "../store";
import "./app.scss";
import "../../styles/style.scss"
import "../../index.scss"

type AppProps = {}

export const App: FC<AppProps> = () => {
  return (
      <AppRouter />
  );
};