import { DescriptionHelper } from "../helpers/DescriptionHelper";

export const CardData = [
  {
    title: "Разметка 1.2",
    description: DescriptionHelper(["ширина 0,1—0,2 м", "1 п.м × 0,1 м = 0,1 кв.м"]),
    imageSrc: "price-form-card__img-1.png",
    paintPrice: 120,
    thermoplasticPrice: 630,
    coldPlasticPrice: 1500,
  },
  {
    title: "Разметка 1.16.1-3",
    description: DescriptionHelper([""]),
    imageSrc: "price-form-card__img-2.png",
    paintPrice: 500,
    thermoplasticPrice: 1600,
    coldPlasticPrice: 1800,
  },
  {
    title: "Разметка 1.13",
    description: DescriptionHelper(["1 п.м = 0.15 кв.м"]),
    imageSrc: "price-form-card__img-3.png",
    paintPrice: 700,
    thermoplasticPrice: 2200,
    coldPlasticPrice: 2200,
  },
  {
    title: "Разметка 1.14.1",
    description: DescriptionHelper(["L = 0.4м, Р = 4-6м", "1 п.м х 6м = 1,6 кв.м"]),
    imageSrc: "price-form-card__img-4.png",
    paintPrice: 300,
    thermoplasticPrice: 1400,
    coldPlasticPrice: 1400,
  },
  {
    title: "Разметка 1.18",
    description: DescriptionHelper(["1,22 кв.м / 1,53 кв.м / 2,36 кв.м"]),
    imageSrc: "price-form-card__img-5.png",
    paintPrice: 500,
    thermoplasticPrice: 1600,
    coldPlasticPrice: 1800,
  },
  {
    title: "Разметка 1.1",
    description: DescriptionHelper([
      "ширина 0,1—0,15 м",
      "1 п.м Х 0,1 м = 0,1 кв.м",
      "1 п.м Х 0,15 м = 0,15 кв.м",
    ]),
    imageSrc: "price-form-card__img-9.png",
    paintPrice: 160,
    thermoplasticPrice: 1000,
    coldPlasticPrice: 1400,
  },
  {
    title: "Разметка 1.6",
    description: DescriptionHelper([
      "ширина 0,1-0,15м",
      "1п.м Х 0,1м = 0,075 кв.м",
      "1п.м Х 0,15м = 0,113 кв.м",
    ]),
    imageSrc: "price-form-card__img-7.png",
    paintPrice: 150,
    thermoplasticPrice: 760,
    coldPlasticPrice: 1800,
  },
  {
    title: "Разметка 1.23.1, 1.24.2, 1.20",
    description: DescriptionHelper(["1.23.1: 1,06 кв.м, 1.20: 1х3м = 1,23 кв.м"]),
    imageSrc: "price-form-card__img-6.png",
    paintPrice: 500,
    thermoplasticPrice: 1800,
    coldPlasticPrice: 2200,
  },
  {
    title: "Разметка 1.17",
    description: DescriptionHelper(["1 п.м = 1,43 кв.м"]),
    imageSrc: "price-form-card__img-8.png",
    paintPrice: 700,
    thermoplasticPrice: 2200,
    coldPlasticPrice: 2200,
  },

  {
    title: "Разметка 1.3",
    description: DescriptionHelper([
      "ширина 0,1—0,2 м",
      "1 п.м Х 0,1 м = 0,2 кв.м",
      "1 п.м Х 0,2 м = 0,4 кв.м",
    ]),
    imageSrc: "price-form-card__img-9.png",
    paintPrice: 120,
    thermoplasticPrice: 630,
    coldPlasticPrice: 1500,
  },
];
