import { FC, useState } from "react";
import { ModalPopUpRight } from "../modal/ModalPopUpRight";
import { imagesArray } from "../../data/ImagesArray";
import { TargetDiv } from "./targerDiv";

type ImageDataProps = {
  visibleCars: boolean;
  visiblePeople: boolean;
};

export const ImageData: FC<ImageDataProps> = ({ visibleCars, visiblePeople }) => {
  const [isShown, setIsShown] = useState<number>(0);

  const renderImages = (layerIndex: number) => {
    const imgSrc = imagesArray[layerIndex]?.img || "img/skin/road_01.png";
    const imgClass = `ip__layer interactive-picture__layer-${layerIndex + 1}`;

    return (
      <>
        <img src={imgSrc} className={imgClass} alt="" />
        <img
          src="img/skin/layer-with-bushes.png"
          className="ip__layer interactive-picture__layer-12"
          alt=""
        />
        {layerIndex > 0 && <img src="img/skin/road_01.png" className="ip__layer false" alt="" />}{" "}
        <TargetDiv setIsShown={setIsShown} />
      </>
    );
  };

  return (
    <div className="interactive-picture">
      {isShown ? (
        <ModalPopUpRight
          isShown={isShown}
          contentLabel="modalWindow"
          className="right-modal"
          overlayClassName="modal-overlay"
        />
      ) : (
        ""
      )}

      {renderImages(isShown)}

      {visibleCars && (
        <img
          className="ip__layer interactive-picture__layer-13"
          src="img/skin/layer-with-cars.png"
          alt=""
        />
      )}

      {visiblePeople && (
        <img
          className="ip__layer interactive-picture__layer-14"
          src="img/skin/layer-with-people.png"
          alt=""
        />
      )}
    </div>
  );
};
