export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const SET_PROJECTS = "SET_PROJECTS";
export const FETCH_ONEPROJECT = "FETCH_ONEPROJECT";
export const SET_ONEPROJECT = "SET_ONEPROJECT";

export const fetchProjects = () => ({
    type: FETCH_PROJECTS,
  });
  
  export const setProjects = (data: any) => ({
    type: SET_PROJECTS,
    payload: data,
  });

  export const fetchOneProject = (slug:string) => ({
    type: FETCH_ONEPROJECT,
    payload: slug
  });
  
  export const setOneProject = (data: any) => ({
    type: SET_ONEPROJECT,
    payload: data,
  });