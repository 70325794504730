import { all } from "redux-saga/effects";
import metadataSaga from "../../models/metadata/metadata.saga";
import pagesSaga from "../../models/pages/pages.saga";
import projectsSaga from "../../models/projects/projects.saga";

export default function* rootSaga() {
  yield all([
    metadataSaga(),
    pagesSaga(),
    projectsSaga()
  ]);
}


