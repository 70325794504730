import { Field } from "formik";
import "./checkBox.scss";

export const CheckBox = ({
  labelClass,
  classNameField,
  htmlForLabel,
  labelText,
  checkLabel,
  onClickField,
}) => {
  
  return (
    <>
      <label
        htmlFor={htmlForLabel}
        className={labelClass}
      >
        {labelText}
      </label>
      <Field
        className={classNameField}
        id={htmlForLabel}
        type="checkbox"
        checked={checkLabel}
        onClick={onClickField}
      />
    </>
  );
};
