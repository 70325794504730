import { FC } from "react";
import "./incomingPage.scss";
import { Colorbox } from "../../components/colorbox/Colorbox";
import { useSelector } from "react-redux";
import { getPages } from "../../models/pages/pages.selectors";
import { Loader } from "../../components/UI/loader/Loader";
import { Helmet } from "react-helmet";

type IncomingPageType = {
  htmlContent: any;
  title: string;
  description: string;
};

export const IncomingPage: FC<IncomingPageType> = ({ htmlContent, title, description }) => {
  const data = useSelector(getPages);

  if (!data) return <Loader />;

  return (
    <div className="main">
      {data ? <Colorbox /> : ""}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="container">
        <section className="page-top">
          <img src="../images/page-top-img.jpg" className="page-top__img" alt="" />
          <div className="row">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <h1>{title}</h1>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="text-m">{description}</div>
            </div>
            <div className="incoming" dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </section>
      </div>
    </div>
  );
};
