import { SET_METADATA, FETCH_DATA } from "./metadata.actions";

const initialState = {
  metadata: null,
};

export const metadataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
      };

    case SET_METADATA:
      return {
        ...state,
        metadata: action.payload,
      };

    default:
      return state;
  }
};
