import { useSelector } from "react-redux";
import { getMetadata } from "../../models/metadata/metadata.selectors";
import "bootstrap/dist/css/bootstrap.min.css";

export const Footer = () => {
  const metadata = useSelector(getMetadata);

  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-2 position-relative">
              <a href="/" className="logo">
                <img src="/images/logo.jpg" alt="" />
              </a>
            </div>
            <div className="col-lg-10 d-none d-lg-block">
              <div className="lightgray copyright">
                © 2023  «ВЕКТОР-М» Разметка и строительство дорог
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-9 col-md-10 col-lg-4 offset-3 offset-md-2">
            <div className="phone">
              <a href={`tel:${metadata?.metadata_phone[0]}`}>{`${metadata?.metadata_phone[0]}`}</a>
            </div>
            <div className="lightgray">/ Максим (онлайн)</div>
            <div className="lightgray">
              <a
                href={`mailto:${metadata?.metadata_email[0]}`}
              >{`${metadata?.metadata_email[0]}`}</a>
            </div>
          </div>

          <div className="col-lg-4">
            <ul className="nav py-5 py-lg-0">
              {metadata?.menu?.header_main_menu.map((e: any, i: number) => {
                return (
                  <li key={i}>
                    <a href={`${e.url}`}>{e.label}</a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col-md-7 d-lg-none mb-3 mb-md-0">
            <div className="lightgray copyright">
              © 2023  «ВЕКТОР-М» Разметка и строительство дорог
            </div>
          </div>
          {/* <div className="col-md-5 col-lg-2 text-md-end">
            <div className="light-text esoteric">
              — Разработка сайта <a href="#">Esoteric&nbsp;Design</a>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};
