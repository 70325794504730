import { SET_PROJECTS, FETCH_PROJECTS, FETCH_ONEPROJECT, SET_ONEPROJECT } from "./projects.actions";

const ProjectInitialState = {
  projects: null,
  oneProject: null,
};

export const projectsReducer = (state = ProjectInitialState, action: any) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
      };

    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

      case FETCH_ONEPROJECT:
      return {
        ...state,
      };

    case SET_ONEPROJECT:
      return {
        ...state,
        oneProject: action.payload,
      };

    default:
      return state;
  }
};

  