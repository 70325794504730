import { FC, useState } from "react";
import { CheckBoxGroup } from "../UI/button/checkBoxGroup/CheckBoxGroup";
import { ImageData } from "../UI/imageInteractive/ImageData";
import "../../styles/_bems.scss";
import "../../styles/style.scss";
import "./prices.scss";
import { CardData } from "../data/CardData";
import { FormCardContent } from "../forms/FormCardContent";
import { AlertModal } from "../UI/modal/alertModal/AlertModal";
import { useModal } from "../../models/modalContext/ModalContext";

export const Prices: FC = () => {
  const [visibleCars, setVisibleCars] = useState(true);
  const [visiblePeople, setVisiblePeople] = useState(true);

  const [carsChecked, setCarsChecked] = useState(true);
  const [peopleChecked, setPeopleChecked] = useState(true);

  const { alertType } = useModal();

  return (
    <section className="main-prices">
      <div className="markup-price__section">
        <div className="markup-price__top-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <h2>
                  А что по <span className="accent">ценам?</span>
                </h2>
                <CheckBoxGroup
                  carsChecked={carsChecked}
                  setCarsChecked={setCarsChecked}
                  peopleChecked={peopleChecked}
                  setPeopleChecked={setPeopleChecked}
                  setVisibleCars={setVisibleCars}
                  setVisiblePeople={setVisiblePeople}
                  visibleCars={visibleCars}
                  visiblePeople={visiblePeople}
                />
              </div>
              <div className="col-lg-4 offset-lg-1">
                <div className="markup-price__section-text">
                  Наведите на разметку, чтобы узнать цену. Итоговая цена может зависить от объёма и
                  трудности нанесения.
                </div>
                <div className="markup-price__link">
                  <a href="/images/vector-m-price_2020.xls" download >скачать полный прайс</a>
                  <span>vector-m-price_2020.xls (561 Кб)</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="interactive-picture__block">
          <ImageData visibleCars={visibleCars} visiblePeople={visiblePeople} />
        </div>
      </div>
      <div className="interactive-picture-form-wrapper interactive-picture-form__close main-screen">
        <div className="interactive-picture-form__close-area"></div>

        <div className="interactive-picture-form">
          {CardData.map((card, index) => {
            return <FormCardContent key={index} card={card} />;
          })}

          <FormCardContent card={CardData[0]} />
        </div>

        {alertType === "success" && (
          <AlertModal
            contentLabel="modalCenter"
            className="center-modal"
            overlayClassName="modal-overlay-center"
            textAlert="Ваши данные успешно отправлены, наш менеджер свяжется с вами!"
          />
        )}

        {alertType === "error" && (
          <AlertModal
            contentLabel="modalCenter"
            className="center-modal"
            overlayClassName="modal-overlay-center"
            textAlert="При отправке данных произошла ошибка, попробуйте повторить позже."
          />
        )}
      </div>
    </section>
  );
};
