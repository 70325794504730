import { FC, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "./colorbox.scss";

export const Colorbox: FC = () => {
  const [imageColorboxes, setImageColorboxes] = useState<string[]>([]);
  const [currentImgColorbox, setCurrentImgColorbox] = useState<number>(1);
  const [modalIsOpen, setIsOpen] = useState(false);

  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const colorboxClass = document.querySelectorAll(".colorbox");

    if (colorboxClass) {
      colorboxClass.forEach((element, index) => {
        element.addEventListener("click", () => {
          setIsOpen(true);
          setCurrentImgColorbox(index);
        });
      });
    }

    const colorboxArray = Array.from(colorboxClass).map(
      (element: Element) => element.getAttribute("src") || ""
    );

    setImageColorboxes(colorboxArray);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const nextImage = () => {
    if (currentImgColorbox < imageColorboxes.length - 1) {
      setCurrentImgColorbox(currentImgColorbox + 1);
    } else {
      setCurrentImgColorbox(0);
    }
  };

  const prevImage = () => {
    if (currentImgColorbox > 0) {
      setCurrentImgColorbox(currentImgColorbox - 1);
    } else {
      setCurrentImgColorbox(imageColorboxes.length - 1);
    }
  };

  return (
    <>
      <Modal
        className={`photoBox--modal ${modalIsOpen ? "is-open" : ""}`}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="ImageModal"
        ariaHideApp={false}
      >
        <img
          ref={imageRef}
          src={imageColorboxes[currentImgColorbox]}
          alt={imageColorboxes[currentImgColorbox]}
        />
        <button className="left" onClick={prevImage}>
          ←
        </button>
        <button className="right" onClick={nextImage}>
          →
        </button>
        <button className="close" onClick={closeModal}>
          ×
        </button>
      </Modal>
    </>
  );
};
