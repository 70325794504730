import { Form, Formik } from "formik";
import { CheckBox } from "../checkBox/CheckBox";

export const CheckBoxGroup = ({
  carsChecked,
  setCarsChecked,
  peopleChecked,
  setPeopleChecked,
  setVisibleCars,
  setVisiblePeople,
  visibleCars,
  visiblePeople,
}) => {
  const handleChangeCars = () => {
    setVisibleCars(!visibleCars);
    setCarsChecked(!carsChecked);
  };

  const handleChangePeople = () => {
    setVisiblePeople(!visiblePeople);
    setPeopleChecked(!peopleChecked);
  };

  return (
    <Formik
      initialValues={{
        visibleCars,
        visiblePeople,
      }}
    >
      <Form className="markup-price__toggler">
        <CheckBox
          labelClass={`checkbox-label ${carsChecked ? "checked" : ""}`}
          classNameField="checkbox-field"
          htmlForLabel="cars"
          labelText="&nbsp; &nbsp; &nbsp;трафик"
          checkLabel={carsChecked}
          onClickField={handleChangeCars}
        />
        <CheckBox
          labelClass={`checkbox-label ${peopleChecked ? "checked" : ""}`}
          classNameField="checkbox-field"
          htmlForLabel="people"
          labelText="&nbsp; &nbsp; &nbsp;люди"
          checkLabel={peopleChecked}
          onClickField={handleChangePeople}
        />
      </Form>
    </Formik>
  );
};
