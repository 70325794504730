import { FC } from "react";
import "./loader.scss";

type LoaderType = {
  className?: string;
}

export const Loader:FC<LoaderType> = ({ className }) => {
  return (
    <div>
      <div className={className}>
        <div className="image-container">
          <img className="loaderImg" src="/img/skin/loader.png" alt="load" />
        </div>
      </div>
    </div>
  );
};
