import { FC } from "react";
import { useModal } from "../../../../models/modalContext/ModalContext";
import { ModalPopUpRight } from "../../modal/ModalPopUpRight";

export const BottomButtonOrder: FC = () => {
  const { setIsOpenModal, setOrderModal, setFeedback } = useModal();

  return (
    <>
      <div className="container">
        <div className="bottom-buttons">
          <div className="row">
            <div className="col-lg-8 offset-lg-4 text-center text-lg-end">
              <a href="#" className="to-up"></a>
              <div
                className="btn order-project"
                onClick={() => {
                  setIsOpenModal(true);
                  setOrderModal(false);
                  setFeedback(true);
                }}
              >
                Заказать проект
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUpRight
        contentLabel="modalWindow"
        className="right-modal"
        overlayClassName="modal-overlay"
      />
    </>
  );
};
