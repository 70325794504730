import React from "react";

export type PublicRoute = {
  path: PublicRoutesNames;
  element: React.ComponentType;
};

export enum PublicRoutesNames {
  HOME = "/",
  PORTFOLIO = "/projects",
  PROJECT = "/project/:slug",
}