
import { HomePage } from "../pages/homePage/HomePage";
import { PortfolioPage } from "../pages/portfolioPage/PortfolioPage";
import { ProjectPage } from "../pages/projectPage/ProjectPage";

import {
  PublicRoute,
  PublicRoutesNames,
} from "./routes.types";

export const publicRoutes: PublicRoute[] = [
  { path: PublicRoutesNames.HOME, element: HomePage },
  { path: PublicRoutesNames.PORTFOLIO, element: PortfolioPage },
  { path: PublicRoutesNames.PROJECT, element: ProjectPage },

];

