import { put, takeEvery, call } from "redux-saga/effects";
import { FETCH_PAGES, setPages } from "./pages.actions";
import { AxiosResponse } from "axios";
import { getPagesRequest } from "./pages.services";

function* fetchSagaPageRequest(request: any, actionCreator: any, payload = {}) {
  try {
    const response: AxiosResponse = yield call(request, payload);
    const action = actionCreator(response.data);
    yield put(action);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function* getPages() {
  yield call(fetchSagaPageRequest, getPagesRequest, setPages);
}

export default function* pagesSaga() {
  yield takeEvery(FETCH_PAGES, getPages);
}
