import { put, takeEvery, call } from "redux-saga/effects";
import { FETCH_ONEPROJECT, FETCH_PROJECTS, setOneProject, setProjects } from "./projects.actions";
import { AxiosResponse } from "axios";
import { getOneProjectRequest, getProjectsRequest } from "./projects.services";

function* fetchSagaProjectRequest(request: any, actionCreator: any, payload = {}) {
  try {
    const response: AxiosResponse = yield call(request, payload);
    const action = actionCreator(response.data);
    yield put(action);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function* getProjects() {
  yield call(fetchSagaProjectRequest, getProjectsRequest, setProjects);
}

function* getOneProject(action: any) {
  const slug = action.payload;
  yield call(fetchSagaProjectRequest, getOneProjectRequest, setOneProject, slug);
}

export default function* projectsSaga() {
  yield takeEvery(FETCH_PROJECTS, getProjects);
  yield takeEvery(FETCH_ONEPROJECT, getOneProject);
}
