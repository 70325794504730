import { FC } from "react";
import Modal from "react-modal";
import "./modalPopUpRight.scss";
import { CardData } from "../../data/CardData";
import { FormCardContent } from "../../forms/FormCardContent";
import { useModal } from "../../../models/modalContext/ModalContext";
import { DefaultButton } from "../button/defaultButton/DefaultButton";

type ModalPopUpRightPropsType = {
  isShown?: number;
  contentLabel: string;
  className: string;
  overlayClassName?: string;
};

export const ModalPopUpRight: FC<ModalPopUpRightPropsType> = ({
  isShown,
  contentLabel,
  className,
  overlayClassName,
}) => {
  const { orderModal, feedback, isOpenModal, setIsOpenModal } = useModal();

  const ModalType = () => {
    switch (true) {
      case feedback:
        return <FormCardContent card={{ ...CardData[1], isShown }} />;

      case orderModal:
        if (isShown) {
          const cardIndex = +isShown - 1;
          if (!isNaN(cardIndex) && cardIndex >= 0 && cardIndex < CardData.length) {
            const selectedCard = CardData[cardIndex];
            return <FormCardContent card={{ ...selectedCard, isShown }} />;
          }
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setIsOpenModal(false)}
      contentLabel={contentLabel}
      className={`Modal ${className}`}
      overlayClassName={`Overlay ${overlayClassName}`}
      ariaHideApp={false}
    >
      <DefaultButton classNamesButton="closeModalButton" buttonText="✖" onClickButton={()=>setIsOpenModal(false)} />
      <ModalType />
    </Modal>
  );
};
