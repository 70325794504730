import "bootstrap/dist/css/bootstrap.min.css";
import { FC } from "react";
import { Description } from "../../components/description/Description";
import { Introduce } from "../../components/intoduce/Introduce";
import { Prices } from "../../components/prices/Prices";

export const HomePage: FC = () => {
  return (
    <>
      <Introduce />
      <Description />
      <Prices/>
    </>
  );
};
