import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  ReactNode,
} from "react";

type ModalContextProps = {
  isOpenModal: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  videoIsOpen: boolean;
  setVideoIsOpen: Dispatch<SetStateAction<boolean>>;
  isAlertOpenModal: boolean;
  setAlertIsOpenModal: Dispatch<SetStateAction<boolean>>;
  orderModal: boolean;
  setOrderModal: Dispatch<SetStateAction<boolean>>;
  feedback: boolean;
  setFeedback: Dispatch<SetStateAction<boolean>>;
  alertType: string | null;
  setAlertType: Dispatch<SetStateAction<"success" | "error" | null>>;
};

export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined
);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isAlertOpenModal, setAlertIsOpenModal] = useState<boolean>(false);
  const [videoIsOpen, setVideoIsOpen] = useState<boolean>(false);

  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);

  return (
    <ModalContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        isAlertOpenModal,
        setAlertIsOpenModal,
        orderModal,
        setOrderModal,
        feedback,
        setFeedback,
        alertType,
        setAlertType,
        videoIsOpen,
        setVideoIsOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
