import { createSelector } from "reselect";
import { RootState } from "../../core/app/store";

const projectsState = (state: RootState) => state?.projectsReducer;

export const getProjects = createSelector([projectsState], (state) => state?.projects);

export const getOneProject = createSelector([projectsState], (state) => state?.oneProject);

// export const getOneProject = (slug:any) => createSelector([getProjects], (data) => {return (
//     data?.filter((e:any)=>e.slug === slug)[0]
// )});
