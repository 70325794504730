import { FC, useState } from "react";
import { Formik, Form, Field } from "formik";
import { CheckBox } from "../UI/button/checkBox/CheckBox";
import { Loader } from "../UI/loader/Loader";
import { useSelector } from "react-redux";
import { getMetadata } from "../../models/metadata/metadata.selectors";
import { useModal } from "../../models/modalContext/ModalContext";
import api from "../../core/app/api";
import "./formCardContent.scss";

type FormCardContentPropsType = {
  card: {
    title: string;
    description: string;
    imageSrc: string;
    paintPrice: number;
    thermoplasticPrice: number;
    coldPlasticPrice: number;
    isShown?: number;
  };
};

const initialValues = {
  name: "",
  phone: "",
  paint: false,
  thermoplastic: false,
  coldPlastic: false,
};

export const FormCardContent: FC<FormCardContentPropsType> = ({
  card: { title, description, imageSrc, paintPrice, thermoplasticPrice, coldPlasticPrice, isShown },
}) => {
  const metadata = useSelector(getMetadata);
  const [paintChecked, setPaintChecked] = useState(false);
  const [thermoChecked, setThermoChecked] = useState(false);
  const [coldChecked, setColdChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { orderModal, feedback, setAlertIsOpenModal, setIsOpenModal, setAlertType } = useModal();

  const handleCheck = (check: boolean, setCheck: (check: boolean) => void) => {
    setCheck(!check);
  };

  const handleFormCheckboxSendData = (
    paint: boolean,
    thermoplastic: boolean,
    coldPlastic: boolean
  ) => {
    const descriptions = [];

    if (paint) {
      descriptions.push("Нанесение краской");
    }
    if (thermoplastic) {
      descriptions.push("Термопластик");
    }
    if (coldPlastic) {
      descriptions.push("Холодный пластик");
    }

    return descriptions.join(", ");
  };

  async function sendCardRequest(values: any) {
    const cardParams = {
      "Feedback[username]": values.name,
      "Feedback[userphone]": values.phone,
      "Feedback[usertext]": handleFormCheckboxSendData(
        values.paint,
        values.thermoplastic,
        values.coldPlastic
      ),
    };
    const cardURLParams = new URLSearchParams(cardParams);
    try {
      setIsFetching(true);
      const response = await api.post("/feedback", cardURLParams);

      if (response.data.status === "ok") {
        setAlertIsOpenModal(true);
        setAlertType("success");
      } else {
        setAlertIsOpenModal(true);
        setAlertType("error");
      }
      if (setIsOpenModal) setIsOpenModal(false);
      return response;
    } catch (error) {
      setAlertIsOpenModal(true);
      setAlertType("error");
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <>
      {feedback === true ? (
        <div className="interactive-picture-form-wrapper interactive-picture-form__close">
          <div className="interactive-picture-form__close-area"></div>
          <div className="interactive-picture-form">
            <div className="price-form-card pfc_1">
              <div className="price-form-card__top price-form-card__topNew">
                <div className="price-form-card__img">
                  <img className="img-responsive" src={`/img/skin/${imageSrc}`} alt={title} />
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  sendCardRequest(values);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      type="text"
                      name="name"
                      className="input_form"
                      placeholder="Ваше имя..."
                      required
                    />

                    <br />

                    <Field
                      type="text"
                      name="phone"
                      className="input_form"
                      placeholder="Ваш телефон..."
                    />

                    <br />

                    <Field
                      as="textarea"
                      name="text"
                      className="input_form textariaForm"
                      placeholder="Какой проект вам нужен?"
                    />

                    <button
                      type="submit"
                      className="button_form button_formNew"
                      disabled={isSubmitting}
                    >
                      Отправить
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            {isFetching && <Loader className={"loaderMiniVisible"} />}
          </div>
        </div>
      ) : orderModal === true ? (
        <div className="interactive-picture-form-wrapper interactive-picture-form__close">
          <div className="interactive-picture-form__close-area"></div>
          <div className="interactive-picture-form">
            <div className={`price-form-card pfc_${isShown}`}>
              <div className="price-form-card__top">
                <div className="price-form-card__img">
                  <img className="img-responsive" src={`/img/skin/${imageSrc}`} alt={title} />
                </div>
                <div className="price-form-card__top-text">
                  <strong>{title}</strong>
                  {description}
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  sendCardRequest(values);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="price-form-card__bottom">
                      <div className="price-form-card__bottom-row">
                        <div className="price-form-card__bottom-item">Нанесение краской</div>
                        <div className="price-form-card__bottom-item">от {paintPrice} руб/м2</div>

                        <CheckBox
                          htmlForLabel="paint"
                          labelClass={`checkbox-label-mark ${paintChecked ? "checked-mark" : ""}`}
                          classNameField="checkmark"
                          checkLabel={paintChecked}
                          onClickField={() => handleCheck(paintChecked, setPaintChecked)}
                          labelText={""}
                        />
                      </div>
                      <div className="price-form-card__bottom-row">
                        <div className="price-form-card__bottom-item">Термопластик</div>
                        <div className="price-form-card__bottom-item">
                          от {thermoplasticPrice} руб/м2
                        </div>
                        <div className="price-form-card__bottom-item">
                          <CheckBox
                            htmlForLabel="thermoplastic"
                            labelClass={`checkbox-label-mark ${
                              thermoChecked ? "checked-mark" : ""
                            }`}
                            classNameField="checkmark"
                            checkLabel={thermoChecked}
                            onClickField={() => handleCheck(thermoChecked, setThermoChecked)}
                            labelText={""}
                          />
                        </div>
                      </div>
                      <div className="price-form-card__bottom-row">
                        <div className="price-form-card__bottom-item">Холодный пластик</div>

                        <div className="price-form-card__bottom-item">
                          от {coldPlasticPrice} руб/м2
                        </div>

                        <CheckBox
                          htmlForLabel="coldPlastic"
                          labelClass={`checkbox-label-mark ${coldChecked ? "checked-mark" : ""}`}
                          classNameField="checkmark"
                          checkLabel={coldChecked}
                          onClickField={() => handleCheck(coldChecked, setColdChecked)}
                          labelText={""}
                        />
                      </div>
                    </div>

                    <Field type="hidden" name="project_name" value="razmetka-m.ru" />

                    <Field
                      type="hidden"
                      name="admin_email"
                      value={`${metadata?.metadata_email[0]}`}
                    />

                    <Field type="hidden" name="form_subject" value={title} />

                    <Field
                      type="text"
                      name="name"
                      className="input_form"
                      placeholder="Ваше имя..."
                      required
                    />

                    <br />

                    <Field
                      type="text"
                      name="phone"
                      className="input_form"
                      placeholder="Ваш телефон..."
                    />
                    <br />

                    <button type="submit" className="button_form" disabled={isSubmitting}>
                      Заказать
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            {isFetching && <Loader className={"loaderMiniVisible"} />}
          </div>
        </div>
      ) : null}
    </>
  );
};
