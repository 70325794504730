export const Description = () => {

    return (
        <div className="container">

    <section className="main-about">
        <div className="row">
            <div className="col-lg-6">
                <h2>Наша  <span className="accent">Миссия</span></h2>
            </div>
            <div className="col-lg-5">
                <p>
                    Главная задача «ВЕКТОР-М» – эффективная реализация проектов строительства, реконструкции и ремонта объектов транспортной инфраструктуры.
                    Это комплексный подход, который является единственной возможностью гарантировать успех. Многолетнее сотрудничество и опыт экспертов в своей отрасли.
                    Сегодня «ВЕКТОР-М» — это развитые дорожные предприятия.
                </p>
            </div>
        </div>
        <div className="main-about__cards">
            <div className="row gx-2">
                <div className="col-10 col-md-5 col-lg-4 col-xl-3 gx-1">
                    <div className="card" style={{ backgroundImage: "url('/images/card-1.jpg')" }}>
                        <div className="card-number">/ 01</div>
                        <a className="card-info" href="/calculate">Нанесение краской</a>
                    </div>
                </div>
                <div className="col-10 col-md-5 col-lg-4 col-xl-3 gx-1">
                    <div className="card" style={{ backgroundImage: "url('/images/card-2.jpg')" }}>
                        <div className="card-number">/ 02</div>
                        <a className="card-info" href="/calculate">Термопластик</a>
                    </div>
                </div>
                <div className="col-10 col-md-5 col-lg-4 col-xl-3 gx-1">
                    <div className="card" style={{ backgroundImage: "url('/images/card-3.jpg')" }}>
                        <div className="card-number card-number--dark">/ 03</div>
                        <a className="card-info" href="/calculate">Холодный пластик</a>
                    </div>
                </div>
                <div className="col-10 col-md-5 col-lg-4 col-xl-3 gx-1">
                    <div className="card" style={{ backgroundImage: "url('/images/card-4.jpg')" }}>
                        <div className="card-quote">..работы выполня&shy;лись в 2 смены и без прерывания дорожного движения...</div>
                    </div>
                </div>
            </div>
            </div>
    </section>

        <div className="main-about__bot">
            <div className="row">
                <div className="col-lg-6 offset-lg-6">
                    <div className="row text-xs">
                        <div className="col-md-6">
                            <p>Наша компания готова выполнить разметку дорог, парковочных мест и прилегающих территорий любой сложности.
                                Мы также рады будем сотрудничать с крупными компаниями, и выехать за пределы города.
                                В нашей компании работают специалисты, которые могут выполнить данную задачу максимально быстро.
                                При желании мы с вами можем заключить долгосрочный договор и работать на постоянной основе.</p>
                        </div>
                        <div className="col-md-6">
                            <p>Стоит отметить, что строительство дорог, в нашей стране стоит крайне остро.
                                Именно по этой причине нужно очень тщательно выбирать компанию, которая занимается
                                этой деятельностью. У нас, вы всегда сможете рассчитывать на своевременное
                                строительство дорог любой сложности. Вы можете заказать ремонт дороги возле
                                своего приусадебного участка, либо частного дома. Кроме всего прочего,
                                вы всегда можете консультироваться со специалистами нашей компании.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    )
}