export const FETCH_DATA = "FETCH_DATA";
export const SET_METADATA = "SET_METADATA";

export const fetchData = () => ({
  type: FETCH_DATA,
});

export const setMetadata = (data: any) => ({
  type: SET_METADATA,
  payload: data,
});
