export const imagesArray = [
    {
      img: "img/skin/road_01.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_02.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_03.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_04.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_05.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_06.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_07.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_08.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_09.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_10.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_11.png",
      className: `ip__layer interactive-picture__layer-`,
    },
    {
      img: "img/skin/road_12.png",
      className: `ip__layer interactive-picture__layer-`,
    },

    {
      img: "img/skin/layer-with-bushes.png",
      className: `ip__layer interactive-picture__layer-`,
    },
  ];