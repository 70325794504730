import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import {metadataReducer} from "../../models/metadata/metadata.reducer";
import {projectsReducer} from "../../models/projects/projects.reducer";
import {pagesReducer} from "../../models/pages/pages.reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  metadataReducer,
  projectsReducer,
  pagesReducer
});

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof reducers>;
export default store;


