import { FC } from "react";
import Modal from "react-modal";
import "./alertModal.scss";
import { useModal } from "../../../../models/modalContext/ModalContext";

type AlertModalPropsType = {
  isShown?: number;
  contentLabel: string;
  className: string;
  overlayClassName?: string;
  textAlert: string;
};

export const AlertModal: FC<AlertModalPropsType> = ({
  contentLabel,
  className,
  overlayClassName,
  textAlert,
}) => {
  const { isAlertOpenModal, setAlertIsOpenModal, setAlertType } = useModal();

  return (
    <Modal
      isOpen={isAlertOpenModal}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setAlertIsOpenModal(false)}
      contentLabel={contentLabel}
      className={`Modal ${className}`}
      overlayClassName={`Overlay ${overlayClassName}`}
      ariaHideApp={false}
    >
      <div className="wrapperAlert">
        <p>{textAlert}</p>
        <button
          className="button_form closeButtonAlertModal"
          onClick={() => {
            setAlertIsOpenModal(false);
            setAlertType(null);
          }}
        >
          Закрыть
        </button>
        <div
          className="closeModal"
          onClick={() => {
            setAlertIsOpenModal(false);
            setAlertType(null);
          }}
        ></div>
      </div>
    </Modal>
  );
};
