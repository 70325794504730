export const SET_PAGES = "SET_PAGES";
export const FETCH_PAGES = "FETCH_PAGES";

export const fetchPages = () => ({
    type: FETCH_PAGES,
  });
  
  export const setPages = (data: any) => ({
    type: SET_PAGES,
    payload: data,
  });