import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProjects } from "../../models/projects/projects.actions";
import { getProjects } from "../../models/projects/projects.selectors";
import { DefaultButton } from "../../components/UI/button/defaultButton/DefaultButton";
import { VideoForPortfolio } from "../../components/videoForPortfolio/VideoForPortfolio";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./portfolioPage.scss";

type Project = {
  id: number;
  month: number;
  year: number;
  slug: string;
  thumbnail_base_url: string;
  thumbnail_path: string;
  title: string;
  block_type: number;
  address: string;
  has_images: string;
  has_video: string;
};

const monthNames: Record<number, string> = {
  1: "Январь",
  2: "Февраль",
  3: "Март",
  4: "Апрель",
  5: "Май",
  6: "Июнь",
  7: "Июль",
  8: "Август",
  9: "Сентябрь",
  10: "Октябрь",
  11: "Ноябрь",
  12: "Декабрь",
};

export const PortfolioPage: FC = () => {
  const dispatch = useDispatch();
  const projects: Project[] = useSelector(getProjects);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const sortArrayByMonth = (array: Project[]): Record<number, Project[]> => {
    const months: Record<number, Project[]> = {};
    array.forEach((item) => {
      if (item.month in months) {
        months[item.month].push(item);
      } else {
        months[item.month] = [item];
      }
    });

    const sortedMonths: Record<number, Project[]> = {};
    Object.keys(months)
      .sort((a, b) => +b - +a)
      .forEach((month) => {
        sortedMonths[+month] = months[+month];
      });

    return sortedMonths;
  };

  const generateYearlySections = (): JSX.Element[] => {
    const projectsByYear: Record<number, Project[]> = {};
    projects.forEach((singleProject) => {
      const year = singleProject.year;
      if (!projectsByYear[year]) {
        projectsByYear[year] = [];
      }
      projectsByYear[year].push(singleProject);
    });

    const sortedYears = Object.keys(projectsByYear).sort((a, b) => +b - +a);

    return sortedYears.map((year) => (
      <div className="container" key={year}>
        <section className="portfolio-section">
          <div className="portfolio-section__info">
            <h2 className="portfolio-section__year">{year}</h2>
            <div className="portfolio-section__count">
              {`${projectsByYear[+year].length} проект${
                projectsByYear[+year].length === 1 ? "" : "а"
              }`}
            </div>
          </div>
          {Object.keys(sortArrayByMonth(projectsByYear[+year]))
            .reverse()
            .map((month) => (
              <div key={month}>
                <h3 className="portfolio-section__month">
                  {monthNames[+month] || "Неизвестный месяц"}
                </h3>
                <div className="row gx-2">
                  {sortArrayByMonth(projectsByYear[+year])[+month].map((page) => (
                    <div
                      key={page.id}
                      className={`${
                        page.block_type === 1 ? "col-lg-6 gx-1" : "col-md-6 col-lg-3 gx-1"
                      }`}
                    >
                      <div
                        className={`${page.block_type === 1 ? "card card--xl" : "card"}`}
                        style={{
                          backgroundImage: `url(${page.thumbnail_base_url}/${page.thumbnail_path})`,
                        }}
                        onClick={() => {
                          navigate(`/project/${page.slug}`, {});
                        }}
                      >
                        <div className="card-number card-number--gray">
                          / {page.id}.{page.month}.{page.year}
                        </div>
                        <DefaultButton
                          classNamesButton="card-text"
                          buttonText={`${page.title}`}
                          onClickButton={() => {
                            navigate(`/project/${page.slug}`, {});
                          }}
                        />
                        {page.block_type === 1 ? (
                          <div className="card-info">
                            <div className="row">
                              <div className="col-md-4">
                                <DefaultButton
                                  classNamesButton="card-info__link"
                                  buttonText="Смотреть"
                                  onClickButton={() => {
                                    navigate(`/project/${page.slug}`, {});
                                  }}
                                />
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="card-info__place">{page.address}</div>
                              </div>
                              <div className="col-6 col-md-4 text-end">
                                <div
                                  className="gallery-btn"
                                  onClick={() => {
                                    navigate(`/project/${page.slug}`, {});
                                  }}
                                >
                                  <img
                                    src="../images/img-gray.png"
                                    alt=""
                                    className="imgForGallery-btn"
                                  />
                                </div>
                                <div
                                  className="video-btn"
                                  onClick={() => {
                                    navigate(`/project/${page.slug}`, {});
                                  }}
                                >
                                  <img
                                    src="../images/play-yellow.png"
                                    alt=""
                                    className="imgForGallery-btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <DefaultButton
                            classNamesButton="card-info"
                            buttonText="Смотреть проект"
                            onClickButton={() => {
                              navigate(`/project/${page.slug}`, {});
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </section>
      </div>
    ));
  };

  if (!projects) {
    return <div>Загрузка</div>;
  }

  return (
    <>
      <Helmet>
        <title>Портфолио</title>
        <meta name="description" content="Наши Проекты" />
      </Helmet>
      {generateYearlySections()}
      <VideoForPortfolio />
    </>
  );
};
